// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aesthetik-js": () => import("./../../../src/pages/aesthetik.js" /* webpackChunkName: "component---src-pages-aesthetik-js" */),
  "component---src-pages-basisbehandlung-js": () => import("./../../../src/pages/Basisbehandlung.js" /* webpackChunkName: "component---src-pages-basisbehandlung-js" */),
  "component---src-pages-bleaching-js": () => import("./../../../src/pages/Bleaching.js" /* webpackChunkName: "component---src-pages-bleaching-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-implantate-js": () => import("./../../../src/pages/Implantate.js" /* webpackChunkName: "component---src-pages-implantate-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keramikinlays-js": () => import("./../../../src/pages/Keramikinlays.js" /* webpackChunkName: "component---src-pages-keramikinlays-js" */),
  "component---src-pages-knirscherschienen-js": () => import("./../../../src/pages/Knirscherschienen.js" /* webpackChunkName: "component---src-pages-knirscherschienen-js" */),
  "component---src-pages-kronen-und-bruken-js": () => import("./../../../src/pages/kronenUndBruken.js" /* webpackChunkName: "component---src-pages-kronen-und-bruken-js" */),
  "component---src-pages-kunststofffullungen-js": () => import("./../../../src/pages/Kunststofffüllungen.js" /* webpackChunkName: "component---src-pages-kunststofffullungen-js" */),
  "component---src-pages-professsionelle-zahnreinigung-js": () => import("./../../../src/pages/professsionelle-zahnreinigung.js" /* webpackChunkName: "component---src-pages-professsionelle-zahnreinigung-js" */),
  "component---src-pages-prothesen-js": () => import("./../../../src/pages/Prothesen.js" /* webpackChunkName: "component---src-pages-prothesen-js" */),
  "component---src-pages-veneers-js": () => import("./../../../src/pages/Veneers.js" /* webpackChunkName: "component---src-pages-veneers-js" */),
  "component---src-pages-vorsorge-js": () => import("./../../../src/pages/vorsorge.js" /* webpackChunkName: "component---src-pages-vorsorge-js" */),
  "component---src-pages-wurzelbehandlung-js": () => import("./../../../src/pages/Wurzelbehandlung.js" /* webpackChunkName: "component---src-pages-wurzelbehandlung-js" */),
  "component---src-pages-zahnversiegelung-js": () => import("./../../../src/pages/Zahnversiegelung.js" /* webpackChunkName: "component---src-pages-zahnversiegelung-js" */)
}

